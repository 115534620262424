<template>
  <div class="order-card" :class="{'is-active': subAction.item.isActive}">
    <v-row @click="subAction.collapse = !subAction.collapse;updateScrollParams();$forceUpdate()" class="align-center"
           style="line-height: 100%; cursor: pointer">
      <OrderStatusIcon class="mr-2 d-block" :order="subAction.item"/>
      Заказ&nbsp;
      <strong class="mr-2">{{ $root.printCost(subAction.item.total) }}</strong>
      <span v-if="subAction.item.datetimecleanObj.date">
                  {{ $moment(subAction.item.datetimecleanObj.date).format('D MMM YYYY г.') }}
                </span>
      <span v-if="subAction.datetime && subAction.datetime.time " class="ml-auto">{{subAction.datetime && subAction.datetime.time ? subAction.datetime.time : '' }}</span>
      <span v-else-if="subAction.item.datetimecleanObj && subAction.item.datetimecleanObj.time" class="ml-auto">
        {{subAction.item.datetimecleanObj.time }}
              <span v-if="subAction.item.datetime_clean_startObj && subAction.item.datetime_clean_startObj.time" class="ml-auto">({{subAction.item.datetime_clean_startObj.time }}</span>
              <span v-if="subAction.item.datetime_clean_endObj && subAction.item.datetime_clean_endObj.time" class="ml-auto">-{{subAction.item.datetimeclean_endObj.time}}</span>
              <span v-if="subAction.item.datetime_clean_startObj && subAction.item.datetime_clean_startObj.time">)</span>
      </span>
      <v-icon class="ml-1">mdi-chevron-{{ subAction.collapse ? 'down' : 'up' }}</v-icon>
    </v-row>
    <v-row v-if="+subAction.item.feedback">
      <strong>Оценка:</strong> <v-icon color="yellow" size="16">mdi-star</v-icon> {{subAction.item.feedback}}
    </v-row>
    <template v-if="!subAction.collapse">
      <v-row class="mt-3">
        <v-col cols="6" class="pa-0">
          <v-row>{{ subAction.item.address.street }} {{ subAction.item.address.house }}</v-row>
          <v-row v-if="subAction.item.manager && subAction.item.manager.fname">
            <v-col cols="5" class="pa-0">
              <span style="opacity: 0.5">Менеджер:</span>
            </v-col>
            <v-col cols="7" class="pa-0">
              {{ subAction.item.manager.fname }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pa-0">
          <div v-if="subAction.item.cleaners && subAction.item.cleaners.length" class="cleaners-list ml-auto">
            <div v-for="cleaner in subAction.item.cleaners" :key="cleaner.n_id" class="cleaner-item"
                 @click.stop="$eventBus.$emit('openCleaner', cleaner.n_id)">
              <div class="cleaner-avatar">
                <img v-if="cleaner.photo" :src="cleaner.photo" alt="" width="42" height="42">
                <v-icon v-else>mdi-account</v-icon>
              </div>
              <div class="cleaner">{{ cleaner.name.split(' ')[1] }} {{ cleaner.name.split(' ')[0][0] }}.</div>
            </div>
          </div>
          <div v-else class="ml-auto cleaner-item">
            <div class="cleaner-icon">
              <v-icon v-if="[0, 1].includes(+subAction.item.orderStatus)">mdi-account-search</v-icon>
              <v-icon v-else>mdi-account-off</v-icon>
            </div>
            <span class="cleaner">{{
                [0, 1].includes(+subAction.item.orderStatus) ? 'Ищем клинера' : 'Клинера не было'
              }}</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div @click="toOrder(subAction.item.n_id)" class="order-card__link">Подробнее</div>
      </v-row>
    </template>
  </div>
</template>

<script>
import OrderStatusIcon from "@/components/OrderStatusIcon";
export default {
  name: "OrderCard",
  components: {OrderStatusIcon},
  props: ['subAction'],
  emits: ['updateScrollParams'],
  methods: {
    toOrder(id) {
      let routeData = this.$router.resolve({path: `/orders/${id}`});
      window.open(routeData.href, 'blank');
    },
    updateScrollParams() {
      this.$emit('updateScrollParams');
    }
  }
}
</script>

<style scoped lang="scss">
.order-card {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #dedede;
  width: 100%;
  margin-bottom: 10px;
  &__link {
    display: block;
    margin-top: 8px;
    padding-top: 8px;
    text-align: center;
    color: #333333;
    border-top: 1px solid #dedede;
    width: 100%;
    cursor: pointer;
  }
}

.cleaners-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 83px);
  justify-content: flex-end;
}

.cleaner-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 83px;

  .cleaner-avatar {
    width: fit-content;
    border-radius: 50%;
    overflow: hidden;
  }

  .cleaner {
    text-align: center;
  }
}

.cleaner-icon {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #abeef4;
  border-radius: 50%;
}
</style>