<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <img v-bind="attrs" v-on="on" width="20" :src="item.sourceInfo.icon"/>
    </template>
    <span style="max-width: 300px;display: block" v-html="item.sourceInfo.title"></span>
  </v-tooltip>
</template>
<script>

export default {
  name: "LeadSourceIcon",
  props: {
    item: Object,
  },
}
</script>
