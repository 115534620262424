export default {
  methods: {
    getExtension(file) {
      let fileSplitted = [];
      if (typeof file === 'string') {
        fileSplitted = file.split('?')[0].split('.');
      } else if (file.attachment_url) {
        fileSplitted = file.attachment_url.split('?')[0].split('.');
      }
      return fileSplitted[fileSplitted.length - 1];
    },
    isImage(file) {
      return ['jpeg', 'png', 'jpg'].includes(this.getExtension(file));
    },
    isAudio(file) {
      return ['mp3', 'ogg', 'jpg'].includes(this.getExtension(file));
    },
    isVideo(file) {
      return ['mp4', 'mov'].includes(this.getExtension(file));
    },
  }
}
