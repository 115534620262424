<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" :color="color">mdi-{{ icon }}</v-icon>
    </template>
    {{order.orderStatusString}}
  </v-tooltip>
</template>

<script>
export default {
  name: "OrderStatusIcon",
  props: ['order'],
  computed: {
    icon() {
      let status = +this.order.orderStatus;
      return status === 4 ? 'close-circle-outline' : [5, 9].includes(status) ? 'check-circle-outline' : 'clock-outline';
    },
    color() {
      let status = +this.order.orderStatus;
      return status === 4 ? 'red' : [5, 9].includes(status) ? 'green' : 'grey';
    }
  }
}
</script>

<style scoped>

</style>