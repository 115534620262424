<template>
  <v-card height="auto">
    <v-card-title class="justify-space-between align-center">Действия
      <v-btn small @click="openChat"><span class="mr-2">Чат</span></v-btn>
    </v-card-title>
    <v-card-text class="py-0">
      <v-timeline
          align-top
          dense
          small
          v-if="actions && Object.keys(actions).length > 0"
      >
        <v-timeline-item
            small color="" v-for="(action, date, index) in actionsWithoutMessages" :key="index">
          <v-row class="">
            <v-col cols="3" class="pt-0" style="padding: 0!important;">
              <!--                    Дата дня. События за этот день-->
              <strong>{{ $root.dateToRus(date) }}</strong>
            </v-col>
            <v-col class="pt-0" style="padding: 0!important;">
              <!--                      Под события-->
              <v-row v-for="(subAction, index1) in action" :key="index1">
                <v-col cols="3" class="pt-0" style="padding: 0!important;">
                  <strong>{{ subAction.datetime.time }}</strong>
                </v-col>
                <v-col class="pt-0" style="padding: 0!important;">
                  <template v-if="subAction.type==='order'">
                    <v-row>
                      <!--                      Подробная информация-->
                      <div class="caption"><b>Новая уборка</b> на {{
                          $root.dateToRus(subAction.item.datetimecleanObj.date)
                        }}
                      </div>
                    </v-row>
                    <v-row>
                      <div class="caption">Сумма: {{ subAction.item.total }}
                        {{ $store.state.user.city.country.currency }}
                      </div>
                    </v-row>
                    <v-row>
                      <div class="caption">Менеджер: {{ subAction.item.manager.fname }}</div>
                    </v-row>
                    <v-row>
                      <div class="caption">Клинер(ы):
                        <a class="cleaner" v-for="cleaner in subAction.item.cleaners" :key="cleaner.n_id"
                           v-on:click.stop="$eventBus.$emit('openCleaner', cleaner.n_id)"
                           style="cursor: pointer;">{{
                            cleaner.name
                          }}</a>
                      </div>
                    </v-row>
                    <v-row>
                      <div class="caption">Статус: {{ subAction.item.orderStatusString }}</div>
                    </v-row>
                  </template>
                  <template v-else-if="subAction.type==='call'">
                    <v-row>
                      <!--                      Подробная информация-->
                      <div class="caption">
                        <b>{{ {incoming: 'Входящий', outgoing: 'Исходящий'}[subAction.item.callDirection] }}
                          звонок</b>
                        {{ $root.printTimeDiff(subAction.item.datetime_end - subAction.item.datetime_create) }}
                        <v-icon v-if="subAction.item.record"
                                @click="$eventBus.$emit('playAudio', subAction.item.record)" color="blue">
                          mdi-play-circle-outline
                        </v-icon>
                      </div>
                    </v-row>
                  </template>
                  <template v-else-if="subAction.type==='message'">
                    <v-row>
                      <!--                      Подробная информация-->
                      <div class="caption" style="padding: 5px 10px;border-radius: 5px;margin-bottom: 10px"
                           :style="{background: +subAction.item.incoming?'#f5f5f5':'#22B1E5', color: +subAction.item.incoming?'':'#fff', }">
                        <span class="mr-1"><LeadSourceIcon :item="subAction.item"/></span>
                        <b>{{ +subAction.item.incoming ? 'Клиент' : 'Менеджер' }}:</b>
                        {{ subAction.item.message }}
                      </div>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-row>
                      <!--                      Подробная информация-->
                      <div class="caption"><b>{{ subAction.item.typeString }}</b></div>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <v-card-subtitle v-else>Действий нет</v-card-subtitle>
    </v-card-text>
  </v-card>
</template>

<script>
import LeadSourceIcon from "../../components/LeadSourceIcon";

export default {
  name: "ClientActions",
  components: {LeadSourceIcon},
  props: ['actions'],
  computed: {
    actionsWithoutMessages() {
      let entries = Object.entries(this.actions)
      entries = entries.map(([date, actions])=>[date, actions.filter(action=>action.type !== 'message')]);
      return Object.fromEntries(entries);
    }
  },
  methods: {
    openChat() {
      this.$refs.chat_modal.open({item: this.item});
    }
  }
}
</script>

<style scoped>

</style>
