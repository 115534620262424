<template>
  <div :class="+message.item.incoming ? 'align-self-start' : 'align-self-end'"
       class="caption message-body position-relative"
       style="font-weight: 500"
       :style="{background: +message.item.incoming?'#efefef':'#22B1E5',
                     color: +message.item.incoming?'':'#fff'}">
              <span v-if="message.type === 'message'" class="mr-1">
                <LeadSourceIcon :item="message.item"/>
              </span>
    <div class="message-attachments-list my-1 ml-2"
         v-if="message.item.attachments">
      <div class="message-file" v-for="(file, i) in JSON.parse(message.item.attachments)" :key="i">
        <div class="img-block" v-if="isImage(file.attachment_url || file)" style="height: 170px">
          <img @click="onImageClick(file.attachment_url || file)"
               :src="file.attachment_url || file" alt="" width="170" style="object-fit: cover; height: 100%">
        </div>
        <AudioPlay v-else-if="isAudio(file.attachment_url || file)" :src="file.attachment_url || file"/>
        <video
            v-else-if="isVideo(file.attachment_url || file)"
            controls
            width="170">
          <source :src="file.attachment_url || file">
        </video>
        <a v-else :href="file.attachment_url || file" target="_blank"
           :style="{color: +message.item.incoming?'':'#fff'}">
          <img :src="file.attachment_url || file" alt="" width="170" style="object-fit: cover; height: 100%">
          Открыть файл
        </a>
      </div>
    </div>
    <b>{{ {sms: 'СМС: ', manager_action:'Действие: ', push_notification:'Пуш: '}[message.type] || ''}}</b>
    <div v-if="message.type==='push_notification' && message.item.title">{{message.item.title}}</div>
    <span v-if="message.item.message || message.item.text || message.item.body" v-html="(message.item.message || message.item.text || message.item.body).replaceAll('\n', '<br>')"></span>
    <video v-if="message.type === 'push_notification' && message.item.type === 'video' && JSON.parse(message.item.data).link"
           class="mt-1"
           controls
           height="170">
      <source :src="JSON.parse(message.item.data).link">
    </video>
    <div class="d-flex justify-space-between align-end" style="width: 100%;">
      <v-btn v-if="message.type === 'push_notification' && message.item.type.includes('survey')"
             class="mt-1" style="background: #eeeeee !important;" x-small
      @click="openSurveyModal(message.item.id)">Открыть опрос</v-btn>
      <div class="ml-auto message-time">{{ message.datetime.time }}</div>
      <v-tooltip top v-if="+message.item.datetime_seen">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on"
              size="10" class="align-self-center ml-1" color="#fff" style="opacity: .6">mdi-check-all</v-icon>
        </template>
        Прочитано
      </v-tooltip>
    </div>
    <SurveyModal ref="survey_modal"/>
  </div>
</template>

<script>
import LeadSourceIcon from "@/components/LeadSourceIcon";
import leadMixin from "@/mixins/leadMixin";
import AudioPlay from "../../../components/AudioPlay.vue";
import SurveyModal from "@/views/cleaners/SurveyModal";

export default {
  name: "Message",
  components: {SurveyModal, AudioPlay, LeadSourceIcon},
  mixins: [leadMixin],
  emits: ['onImageClick'],
  props: ['message'],
  methods: {
    onImageClick(file) {
      this.$emit('onImageClick', file);
    },
    openSurveyModal(survey_id) {
      this.$refs.survey_modal.open({cleaner_id: this.message.item.user_id, survey_id});
    }
  }
}
</script>

<style scoped lang="scss">

.message-body {
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 300px;
  min-width: 120px;
  overflow: hidden;
  &.client {

  }

  &.manager {

  }
}

.message-time {
  text-align: right;
  opacity: 0.6;
  font-size: 12px;
}
</style>
