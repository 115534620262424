<template>
  <div class="chat-container">
    <div class="chat-inner">
      <div
          v-if="scrollContainerParams.scrollHeight - scrollContainerParams.offsetHeight > 100
           && scrollContainerParams.scrollHeight - scrollContainerParams.scrollTop > scrollContainerParams.offsetHeight+100"
          class="scroll-down-button" @click="scrollToEnd(false)">
        <v-icon>mdi-arrow-down</v-icon>
      </div>
      <!--      <v-icon class="btn-close-modal" @click="opened=false" size="36">mdi-close</v-icon>-->
      <viewer style="display: none" ref="image_viewer" :images="images.map(imgObj=>imgObj.src)">
        <img v-for="(img, i) in images" :key="i" :src="img.src" :alt="img.alt" width="0" height="0">
      </viewer>
      <div class="messages-container pr-2" @scroll="updateScrollParams" ref="scrollContainer">
        <div class="d-flex flex-column" style="gap: 10px" v-for="([date, action], index) in actions" :key="index">
          <v-row class="justify-center py-2 date_title">{{ $moment(date).format('D MMMM YYYY') }}</v-row>
          <div class="message-container d-flex" v-for="(subAction, index1) in action" :key="index1"
               :class="
               subAction.type !== 'message_group' &&
                subAction.type !== 'order' ? subAction.item.callDirection === 'incoming' ||
                !['sms', 'push_notification', 'manager_action'].includes(subAction.type)
            ? 'justify-start' : 'justify-end' : 'justify-center'">
            <div v-if="subAction.type === 'message_group'" class="d-flex flex-column message-group">
              <div v-if="subAction.items.length > 3"
                   class="collapse-group-message-label d-flex justify-space-between py-2"
                   style="cursor: pointer"
                   @click="subAction.collapse = !subAction.collapse; updateScrollParams(); $forceUpdate();">
                {{ subAction.collapse ? 'Развернуть сообщения' : 'Свернуть сообщения' }}
                ({{ subAction.items.length - 1 }}
                сбщ.)
                <v-icon v-if="subAction.collapse">mdi-chevron-down</v-icon>
                <v-icon v-else>mdi-chevron-up</v-icon>
              </div>
              <template v-if="!subAction.collapse || subAction.items.length <= 3">
                <Message v-for="(msg, i) in subAction.items.slice(0, subAction.items.length - 1)" :key="i"
                         :message="msg" @onImageClick="openImageViewer"/>
              </template>
              <Message :message="subAction.items[subAction.items.length - 1]" @onImageClick="openImageViewer"/>
            </div>
            <OrderCard v-else-if="subAction.type==='order'" :sub-action="subAction"
                       @updateScrollParams="updateScrollParams"/>
            <template v-else-if="subAction.type==='call'">
              <div class="my-2">
                <!--                      Подробная информация-->
                <div class="caption">
                  <b>{{ {incoming: 'Входящий', outgoing: 'Исходящий'}[subAction.item.callDirection] }} звонок</b>
                  {{ $root.printTimeDiff(subAction.item.datetime_end - subAction.item.datetime_create) }}
                  <v-icon v-if="subAction.item.record"
                          @click="$eventBus.$emit('playAudio', subAction.item.record)" color="blue">
                    mdi-play-circle-outline
                  </v-icon>
                </div>
                <div class="call-time text-end">{{ subAction.datetime.time }}</div>
              </div>
            </template>
            <template v-else-if="subAction.type === 'push_notification'">
              <Message :message="subAction"/>
            </template>
            <template v-else-if="subAction.type === 'manager_action'">
              <Message :message="{...subAction, item: {incoming: 0, text: subAction.title}}"/>
            </template>
            <template v-else-if="subAction.type === 'sms'">
              <Message :message="subAction"/>
            </template>
            <template v-else>
              <Message :message="{...subAction, item: {incoming: 1, text: subAction.item.typeString}}"
                       @onImageClick="openImageViewer"/>
              <!--              <v-row>
                              &lt;!&ndash;                      Подробная информация&ndash;&gt;
                              <div class="caption"><b>{{ subAction.item.typeString }}</b></div>
                            </v-row>-->
            </template>
          </div>
        </div>
        <div ref="end_of_chat"></div>
      </div>
    </div>
    <v-textarea v-model="message" v-if="showTextarea"
                ref="message_input"
                class="chat-input px-2 align-start" placeholder="Написать сообщение..."
                prepend-icon="mdi-paperclip"
                multiple
                @click:prepend="attachFile"
                @keydown.shift.enter.prevent="sendMessage()" rows="4" no-resize>
      <template v-slot:append>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip, attrs: tooltipAttrs }">
            <v-menu
                v-model="menu_emoji"
                :close-on-content-click="false"
                transition="scale-transition"
                top
                left
                max-width="300"
                min-width="auto"
                @input="onEmojiMenuOpen">
              <template v-slot:activator="{ on: onMenu, attrs: menuAttrs }">
                <v-icon v-bind="[tooltipAttrs, menuAttrs]"
                        v-on="{...onTooltip, ...onMenu}" class="mr-1">mdi-emoticon
                </v-icon>
              </template>
              <v-row style="background: #FFFFFF">
                <span class="px-2" v-for="(emoji, i) in emojis" :key="i"
                      style="font-size: 24px; cursor: pointer"
                      @click="onSelectEmoji(emoji)">{{ emoji }}</span>
              </v-row>
            </v-menu>
          </template>
          <span>Выбрать эмодзи</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.isClientLead">
          <template v-slot:activator="{ on: onTooltip, attrs: tooltipAttrs }">
            <v-menu
                ref="menu_template_msgs"
                v-model="menu_template_msgs"
                :close-on-content-click="false"
                transition="scale-transition"
                top
                left
                max-width="300"
                min-width="auto"
                @input="onTemplateMessagesMenuOpen">
              <template v-slot:activator="{ on: onMenu, attrs: menuAttrs }">
                <v-icon v-bind="[tooltipAttrs, menuAttrs]"
                        v-on="{...onTooltip, ...onMenu}" class="mr-1">mdi-comment-text-multiple
                </v-icon>
              </template>
              <div class="menu-container">
                <v-text-field
                    ref="search_templates_input"
                    outlined
                    autofocus
                    v-model="searchQueryTemplateMsgs"/>
                <div class="messages-list py-2 px-1">
                  <div class="messages-list-item py-2" v-for="(msg, i) in filteredTemplateMessages" :key="i"
                       @click="selectMsgTemplate(preparedMessage(msg.message))">
                    <div class="messages-list-item-title">{{ msg.title }}</div>
                    <div class="messages-list-item-text">{{ preparedMessage(msg.message) }}</div>
                  </div>
                </div>
              </div>
            </v-menu>
          </template>
          <span>Выбрать шаблон сообщения</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" @click="sendMessage()">mdi-send</v-icon>
          </template>
          <span>Shift + Enter</span>
        </v-tooltip>
      </template>
    </v-textarea>
    <v-file-input ref="file_input" class="pa-0 ma-0" @change="onFileSelect"
                  style="width: 0; height: 0; opacity: 0; overflow: hidden"/>
  </div>
</template>

<script>
import LeadSourceIcon from "@/components/LeadSourceIcon";
import Message from "@/views/leads/components/Message";
import OrderStatus from "@/components/OrderStatus";
import leadMixin from "@/mixins/leadMixin";
import OrderStatusIcon from "@/components/OrderStatusIcon";
import globalMixins from "@/mixins/globalMixins";
import OrderCard from "@/components/OrderCard";

export default {
  name: "ChatContainer",
  components: {OrderCard, OrderStatusIcon, OrderStatus, Message, LeadSourceIcon},
  props: ['item', 'showTextarea', 'messageRequestMethod', 'templateMessages'],
  mixins: [leadMixin, globalMixins],
  data() {
    return {
      opened: false,
      message: '',
      sending: false,
      file: null,
      scrollOffset: 0,
      initialScrollOffset: 0,
      scrollContainerParams: {
        scrollHeight: 0,
        offsetHeight: 0,
        scrollTop: 0
      },
      menu_template_msgs: false,
      menu_emoji: false,
      searchQueryTemplateMsgs: '',
      emojis: ['🩵', '😊️', '😔', '✨', '🥹'],
    }
  },
  mounted() {
    this.scrollContainerParams.offsetHeight = this.$refs.scrollContainer.offsetHeight;
    this.scrollContainerParams.scrollHeight = this.$refs.scrollContainer.scrollHeight;
  },
  methods: {
    attachFile() {
      this.$refs.file_input.$refs.input.click();
    },
    onFileSelect(file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      this.axios.post('https://cleanbros.ru/upload', formData)
          .then(data => {
            this.file = null;
            this.sendMessage(data.data.response);
          })
          .catch(e => {
            this.$root.notify(e, 'error')
          })
    },
    itemID() {
      if (this.messageRequestMethod === 'leads') return this.item.id;
      else if (this.messageRequestMethod === 'cleaner') return this.item.cleaner.n_id;
      else return this.item.client.n_id;
    },
    sendMessage(file) {
      if (!this.message && this.sending && !file) return;
      this.sending = true;

      // console.log(`${this.messageRequestMethod}/answer/${this.itemID()}`)
      // return;
      this.$store.state.server.request(`${this.messageRequestMethod}/answer/${this.itemID()}`,
          {message: this.message, attachment_url: file},
          () => {
            this.sending = false;
            this.message = '';
            this.$emit('on-lead-answered');
          }, (data) => {
            this.sending = false;
            this.$root.notify(data.error, 'error')
          }
      )
    },
    scrollToEnd(firstScroll = false) {
      let offsetScroll = this.$refs.scrollContainer.scrollHeight - this.$refs.scrollContainer.offsetHeight;
      this.$refs.scrollContainer.scrollTo({top: offsetScroll, behavior: !firstScroll ? 'smooth' : 'auto'});
      if (firstScroll) this.initialScrollOffset = this.$refs.scrollContainer.scrollTop;
    },
    updateScrollParams() {
      setTimeout(() => {
        this.scrollContainerParams.scrollTop = this.$refs.scrollContainer.scrollTop;
        this.scrollContainerParams.scrollHeight = this.$refs.scrollContainer.scrollHeight;
      }, 100);
    },
    openImageViewer(image) {
      let img = image.attachment_url || image;
      let imgIndex = this.images.findIndex(imgObj => imgObj.src === img);
      this.$refs.image_viewer.$viewer.view(imgIndex);
    },
    onSelectEmoji(emoji) {
      let input = this.$refs.message_input.$el.querySelector('textarea');
      let pos = input.selectionStart;
      let oldStr = this.message;
      this.message = oldStr.slice(0, pos) + `${emoji}` + oldStr.slice(pos);
      input.focus();
      this.menu_emoji = false;
    },
    selectMsgTemplate(message) {
      this.message = message;
      this.menu_template_msgs = false;
    },
    preparedMessage(message) {
      return message.replace('_clientname_', this.item.client.name || 'Уважаемый клиент')
          .replace('_managername_', this.user.fname);
    },
    onTemplateMessagesMenuOpen() {
      this.searchQueryTemplateMsgs = '';
      setTimeout(() => {
        let input = this.$refs.search_templates_input.$el.querySelector('input');
        input.focus();
      }, 100)
    },
    onEmojiMenuOpen() {

    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    filteredTemplateMessages() {
      return this.templateMessages.filter(msg => msg.title.toLowerCase().includes(this.searchQueryTemplateMsgs.toLowerCase()));
    },
    actions() {
      let actionsByDate = {};
      if (this.item.client && this.item.client.history) actionsByDate = this.clone(this.item.client.history.actions);
      else if (this.item.cleaner && this.item.cleaner.history) actionsByDate = this.clone(this.item.cleaner.history.actions);
      let i = 0;
      for (let k in actionsByDate) {
        actionsByDate[k] = actionsByDate[k].reverse();
        let result = [];
        let temp = [];
        let arr = actionsByDate[k];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].type === 'order') {
            arr[i].collapse = true;
          }
          if (arr[i].type === 'message') {
            temp.push(arr[i]);
          } else {
            if (temp.length > 0) {
              result.push({type: 'message_group', items: temp, collapse: i});
              temp = [];
            }
            result.push(arr[i]);
          }
        }
        if (temp.length > 0) {
          result.push({type: 'message_group', items: temp, collapse: i});
        }
        actionsByDate[k] = result;
        i++;
      }
      return Object.entries(actionsByDate).reverse();
    },
    images() {
      let images = [];
      let actionsByDate = {};
      if (this.item.client && this.item.client.history) actionsByDate = this.clone(this.item.client.history.actions);
      else if (this.item.cleaner && this.item.cleaner.history) actionsByDate = this.clone(this.item.cleaner.history.actions);
      actionsByDate = Object.entries(actionsByDate);
      actionsByDate.forEach(([date, actions]) => {
        actions.forEach(action => {
          if (action.item && action.item.attachments) {
            for (let file of JSON.parse(action.item.attachments)) {
              if (this.isImage(file)) {
                images.push({src: file.attachment_url || file, alt: action.item.message});
              }
            }
          }
        })
      });
      return images;
    }
  },
}
</script>

<style scoped lang="scss">
.chat-container {
  overflow: hidden;
}

.chat-inner {
  height: 70vh;
  overflow: hidden;
  position: relative;

  .scroll-down-button {
    z-index: 2;
    position: absolute;
    bottom: 40px;
    left: 20px;
    display: flex;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    background: #eeeeee;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #FFFFFF;
  }

  .messages-container {
    height: 96%;
    overflow: auto;
  }
}

.chat-input {
  border: 1px solid #eeeeee;
}

.message-attachments-list {
  overflow: hidden;

  img {
    border-radius: 8px;
    object-fit: cover;
  }
}

.collapse-group-message-label {
  width: 100%;
  cursor: pointer;
}

.message-container {
  width: 100%;
}

.message-group {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #dedede;
  padding: 5px 10px;
  gap: 10px;
}

.call-time {
  text-align: right;
  opacity: 0.6;
  font-size: 12px;
}

.date_title {
  position: sticky;
  top: -2px;
  background: white;
  z-index: 1;
}

.menu-container {
  background: #fff;
}

.messages-list {
  max-height: 500px;
  overflow: auto;

  &-item {
    overflow: hidden;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;

    &-title {
      font-size: 15px;
      font-weight: 500;
    }

    &-text {
      font-size: 12px;
      opacity: .8;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
}
</style>
