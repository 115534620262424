<template>
  <span style="cursor: pointer" @click.stop="$eventBus.$emit('playAudio', src)">
    Аудио
    <v-icon>
      mdi-play-circle-outline
    </v-icon>
  </span>
</template>
<script>

export default {
  name: "AudioPlay",
  props: {
    src: {},
  },
}
</script>
<style scoped>
</style>
